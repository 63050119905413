import moment from 'moment-timezone';
import { UseFormGetFieldState } from 'react-hook-form';
import { ArrayOfObjectsAny } from 'utility/interfaces';
import * as yup from 'yup';
import { FieldNameKey, bajajConfig, cholaConfig, fieldSchema, hdfcConfig, iciciConfig, insurerConfig, insurerName, libertyConfig, magmaConfig, orientalConfig, royalSundaramConfig, shriramConfig, tataConfig, unitedConfig, kotakConfig, relianceConfig, nationalConfig } from './insurer.config';
import { dummyUnitedConfig } from 'dummyConfig';
import modifyValidationAction from 'redux/actions/validation';
import { store } from 'redux/storeConfig/store';

moment.tz.setDefault('Asia/Kolkata');
const USER = JSON.parse(localStorage.getItem('AEGIS_USER') as any) as any;
const allowedEngineNumberRegex = [
  /^(?:[0-9]-[0-9]{3}\*[0-9]{5})\*$/, // {1}number-{3}numbers*{5}numbers* KTM
  /^(?:[a-zA-Z]-[0-9]{3}\*[0-9]{5})\*$/,  // {1}alphabet-{3}numbers*{5}numbers* KTM
  /^(?:[a-zA-Z]{3}-[a-zA-Z]{2}[0-9]{6})(?:\*?)$/, // {3}alphabets-{2}alphabets*{6}numbers* Classic Legends
  /^[A-Z0-9]{7}-[A-Z]{2}[0-9]{7}$/, // 2V49FMM-RN1270448 KEEWAY
  /^(?!\d+$)(?![a-zA-Z]+$)[a-zA-Z\d]+$/, // General alphanumeric
  /^[A-Z]{3}[0-9]{1}-[0-9]{6}$/ // BGA4-215602
]; 

export type DependentFields = {
  [key :string]: string | number | string[] | undefined;
}
export const electricalEnabledInsurers = ['icici', 'tata', 'united', 'bajaj'];
export const accessoriesNameEnabledInsurers = ['united', 'shriram', 'chola'];
export const nonElectricalEnabledInsurers = ['icici', 'tata', 'united', 'bajaj', 'chola'];
export const areaEnabledInsurers = ['bajaj', 'liberty', 'magma', 'shriram', 'hdfc', 'chola'];
export const financerDropdownEnabledInsurers = ['liberty', 'magma', 'shriram', 'united', 'tata', 'chola', 'reliance'];
export const companyTypeInsurers = ['tata', 'united'];
export const handicapEnabledInsurers = [''];
export const paEnabledInsurers = ['tata', 'united', 'icici', 'shriram', 'bajaj', 'chola'];
export type IFormInputs=  DependentFields&{
  // * Insurance Details
  newVehicle: string;
  bodyType: string;
  insurer: string;
  insurerType: string;
  insurerName: string;
  registrationType: string;
  policyType: string;
  transferCase?: string;
  previousPolicyExpired?: string;
  claimInPreviousPolicy?: string;
  noOfClaimsInPreviousPolicy?: string;
  previousNcb?: string;
  riskStartDate: string | Date;
  zeroDep?: string;
  consumables?: string;
  engineProtect?: string;
  rti?:string;
  indemnity?:string;
  llpd?:string; // legal liability to paid driver
  numberOfUnnamedPA?: number;
  paToUnnamedPerson?: string;
  electricalAccessories: string;
  electricalNames?: string[];
  nonElectricalNames?: string[];
  nonElectricalAccessories: string;

  // * Previous Insurance Details
  previousOdInsurer?: string;
  previousOdPolicyNumber?: string;
  previousOdEndDate?: string;
  previousTpInsurer?: string;
  previousTpPolicyNumber?: string;
  previousTpEndDate?: string;

  // * Vehicle Details
  registrationDate: string | Date;
  manufacturingYear: string;
  rto: string;
  model: string;
  engineNumber: string;
  chassisNumber: string;
  registrationNumber?: string;
  batteryNumber?:string
  hypothecated: string;
  financer?: string;
  financerAddress?: string;

  // * Inspection Details
  inspection?: {
    id?: string;
    folderId?: string;
    finalized?: boolean;

    // United inspection fields
    front?: string;
    left?: string;
    right?: string;
    rear?: string;
    odometer?: string;
    chassisNumber?: string;
    report?: string;
    agent?: string;
  }

  // * Customer Details
  customerType: string;
  companyType?: 'company' | 'proprietor' | 'partnership' | 'public' | 'private';
  owner: {
    salutation: string;
    name: string;
    dob: string;
    documentType: 'pan';
    documentNumber: string;
  }
  handicap?: string;
  proprietor?:string;
  proprietorName?:string;
  companyName?: string;
  gstin?: string;
  doi?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  gender?: string;
  mobile: string;
  email: string;
  pincode: any;
  state: string;
  city: string;
  insurerSpecific?: {
    area?: string,
    customerName?:string
  };
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;

  // * KYC Details
  ckycType: string;
  photoFileId?: string;
  documentType: string;
  documentNumber: string;
  documentFileId: string;
  documentBackFileId?: string;
  ckycNumber?: string;
  
  supportingDocumentType: string;
  supportingDocumentNumber: string;
  supportingDocumentFileId: string;
  supportingDocumentBackFileId?: string;
  supportingckycNumber?: string;

  // * Nominee Details
  nomineeName?: string;
  nomineeRelation?: string;
  nomineeAge?: string;
  nomineeGender?: string;

  // * IDV & Premium Details
  idv?: number;
  quoteDisabled?: boolean;
  premium: number;
} 
const ownerSchema = yup.object().shape({
  salutation: yup.string().required('Please select salutation.'),
  name:  yup.string().required('Name is required'),
  dob:  yup.date().required('Date of birth is required')
    .typeError('Date of birth must be a valid date'),
  documentNumber:  yup.string()
    .required('Document number (PAN) is required')
    .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})$/, 'Document number (PAN) is not valid'),
})

const maxDob = new Date(moment().format('YYYY-MM-DD'));
maxDob.setFullYear(maxDob.getFullYear() - 18);
const minDob = new Date(moment().format('YYYY-MM-DD'));
minDob.setFullYear(minDob.getFullYear() - 70);

// Default No Options Messages
const noOptionsMessage = {
  loading: 'Loading...',
  notFound: 'Not found',
  coverageNotFound: 'Coverage not supported, please select other insurer.'
};

const initialValidation = {
  // * Insurance Details
  newVehicle: yup.string()
    .required('Please select if the vehicle is new.'),
  bodyType: yup.string()
    .required('Please select a Body Type.')
    .oneOf(['BIKE', 'SCOOTER', 'EV'], 'Invalid Body Type selected.'),
  insurer: yup.string()
    .required('Please select an Insurer.'),
  policyType: yup.string()
    .when('newVehicle', {
      is: 'Y',
      then: yup.string()
        .required('Please select a Coverage.')
        .oneOf(['od1tp5','od1tp1'], 'Invalid Coverage selected.'),
      otherwise: yup.string()
        .required('Please select a Coverage.')
        .oneOf(['od1tp1', 'od1', 'tp1'], 'Invalid Coverage selected.')
    }),
  transferCase: yup.string()
    .when('newVehicle', {
      is: 'N',
      then: yup.string()
        .required('Please select if it is a transfer case.')
        .oneOf(['Y', 'N'], 'Invalid value.')
    }),
  previousPolicyExpired: yup.string()
    .when(['newVehicle'], {
      is: (newVehicle) => newVehicle === 'N',
      then: yup.string()
        .required('Please select if previous policy has expired.')
        .oneOf(['not expired', 'within 90 days', 'more than 90 days'], 'Invalid value.')
    }),
  claimInPreviousPolicy: yup.string()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => newVehicle === 'N' && policyType.includes('od'),
      then: yup.string()
        .required('Please select if there is a claim in the previous policy.')
        .oneOf(['Y', 'N'], 'Invalid value.')
    }),
  noOfClaimsInPreviousPolicy: yup.string()
    .when(['insurer', 'claimInPreviousPolicy'], {
      is: (insurer, claimInPreviousPolicy) => {
        return claimInPreviousPolicy === 'Y'
               && insurer === 'bajaj';
      },
      then: yup.string()
      .required('Please select total claims in previous policy.')
    }),
  previousNcb: yup.string()
    .when(['newVehicle', 'policyType', 'previousPolicyExpired', 'claimInPreviousPolicy'], {
      is: (newVehicle, policyType, previousPolicyExpired, claimInPreviousPolicy) => {
        return newVehicle === 'N'
               && policyType
               && ['od1tp1', 'od1'].includes(policyType)
               && previousPolicyExpired
               && previousPolicyExpired !== 'more than 90 days'
               && claimInPreviousPolicy
               && claimInPreviousPolicy === 'N';
      },
      then: yup.string()
        .required('Please select previous NCB.')
        .oneOf(['0', '20', '25', '35', '45', '50'], 'Invalid value.')
    }),
  riskStartDate: yup.date()
    .required('Risk Start Date is required.')
    .typeError('Invalid Risk Start Date.')
    .min(moment().format('YYYY-MM-DD'), 'Risk Start Date cannot be before today.'),
  zeroDep: yup.string()
    .when('policyType', {
      is: (policyType) => policyType.includes('od'),
      then: yup.string()
        .required('Please select if nil dep cover is needed.'),
    }),
  consumables: yup.string()
    .when(['policyType', 'insurer', 'bodyType'], {
      is: (policyType, insurer, bodyType) => policyType.includes('od') && insurer === 'bajaj' && bodyType !== 'EV',
      then: yup.string()
        .required('Please select if consumables cover is needed.'),
    }),
  engineProtect: yup.string()
    .when(['policyType', 'insurer', 'bodyType'], {
      is: (policyType, insurer, bodyType) => bodyType !== 'EV' && policyType === 'od1tp5' && ['bajaj', 'chola', 'united'].includes(insurer),
      then: yup.string()
        .required('Please select if engine protect cover is needed.'),
    }),
  rti: yup.string()
    .when(['policyType', 'insurer'], {
      is: (policyType, insurer) => policyType !== 'od1tp1' && (insurer === 'united' || insurer === 'tata'),
      then: yup.string()
        .required('Please select if RTI cover is needed.'),
    }),
  paToUnnamedPerson: yup.string()
  .when(['insurer', 'policyType'], {
    is: (insurer, policyType) => (paEnabledInsurers.includes(insurer) && policyType === 'od1tp5') || (insurer === 'united' && policyType === 'od1tp1'),
    then: yup.string()
      .required('Please select if PA To Unnamed Person cover is needed.'),
  }),
  numberOfUnnamedPa: yup.number().typeError('Value should be a number.').when(['paToUnnamedPerson'], {
    is: (paToUnnamedPa) => paToUnnamedPa === 'Y',
    then: yup.number().typeError('Value should be a number.').required('Number of Unnamed PA is required.')
    .min(1, 'Number of Unnamed PA should be at least 1.')
    .max(2, 'Number of Unnamed PA should not exceed 2.')
  }),
  electricalAccessories: yup.string()
  .when(['insurer', 'policyType'], {
    is: (insurer, policyType) => electricalEnabledInsurers.includes(insurer) && policyType === 'od1tp5',
    then: yup.string()
      .required('Please select if any electrical accessories.'),
  }),
  electricalAccessoriesValue: yup.number().typeError('Value should be a number.').when(['electricalAccessories'], {
    is: (electricalAccessories) => electricalAccessories === 'Y',
    then: yup.number().typeError('Value should be a number.').required('Electrical Accessories cost is required.')
    .min(2000, 'Electrical cost should at least be 2000')
  }),
  nonElectricalAccessories: yup.string()
  .when(['insurer', 'policyType'], {
    is: (insurer, policyType) => nonElectricalEnabledInsurers.includes(insurer) && policyType === 'od1tp5',
    then: yup.string()
      .required('Please select if any nonelectrical accessories.')
  }),
  nonElectricalAccessoriesValue: yup.number().typeError('Value should be a number.').when(['nonElectricalAccessories','handicap'], {
    is: (nonElectricalAccessories, handicap) => nonElectricalAccessories === 'Y' ,
    then: yup.number().typeError('Value should be a number.').required('Non-electrical Accessories cost is required.')
    .min(2000, 'Non-Electrical cost should at least be 2000')
  }),
  nonElectricalNames: yup.array().when(['nonElectricalAccessories', 'insurer'], {
    is: (nonElectricalAccessories, insurer) => nonElectricalAccessories === 'Y' && accessoriesNameEnabledInsurers.includes(insurer),
    then:yup.array().required('Non-electrical Accessories names are required.')
  }),
  electricalNames: yup.array().when(['electricalAccessories', 'insurer'], {
    is: (electricalAccessories, insurer) => electricalAccessories === 'Y' && accessoriesNameEnabledInsurers.includes(insurer),
    then:yup.array().required('Electrical Accessories names are required.')
  }),
  indemnity: yup.string()
    .when(['registrationType'], {
      is: (registrationType) => registrationType === 'commercial' || registrationType === 'hirepurchase',
      then: yup.string()
        .required('Please select if Indemnity to Hirer/Owner is needed.'),
    }),
  llpd: yup.string()
    .when(['registrationType'], {
      is: (registrationType) => registrationType === 'commercial' || registrationType === 'hirepurchase',
      then: yup.string()
        .required('Please select whether driver has the legal liability or not.'),
    }),
  previousOdInsurer: yup.string()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1tp1', 'od1'].includes(policyType);
        return enable
      },
      then: yup.string()
        .required('Please select Insurer.')
        .matches(/^[a-z ,.'-]+$/i, 'Invalid Insurer Name.')
        // .test('united', 'We will not be able to issue this renewal policy in united, we are working with united to fix this issue.', function (value) {
        //   const insurer  = this?.parent?.insurer;
        //   console.log({ value, insurer });
        //   return value && value?.toLowerCase()?.includes('united') && insurer && insurer === 'united' ? false : true;
        // })
    }),
  previousOdPolicyNumber: yup.string()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1tp1', 'od1'].includes(policyType);
        return enable
      },
      then: yup.string()
        .required('Please enter Policy Number.')
    }),
  previousOdEndDate: yup.date()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1tp1', 'od1'].includes(policyType);
        return enable
      },
      then: yup.date()
        .required('Please select Policy End Date.')
    }),
  previousTpInsurer: yup.string()
    .when(['newVehicle', 'policyType','insurer'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1', 'tp1'].includes(policyType);
        return enable
      },
      then: yup.string()
        .required('Please select Insurer.')
        // .test('united', 'In case of renewals in united, we will not be able to issue policy, we are working with united to fix this issue.', function (value) {
        //   const insurer  = this?.parent?.insurer;
        //   console.log({ value, insurer });
        //   return value && value?.toLowerCase()?.includes('united') && insurer && insurer === 'united' ? false : true;
        // })
    }),
  previousTpPolicyNumber: yup.string()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1', 'tp1'].includes(policyType);
        return enable
      },
      then: yup.string()
        .required('Please enter Policy Number.')
    }),
  previousTpEndDate: yup.date()
    .when(['newVehicle', 'policyType'], {
      is: (newVehicle, policyType) => {
        const enable = newVehicle === 'N'
                       && policyType
                       && ['od1', 'tp1'].includes(policyType);
        return enable
      },
      then: yup.date()
        .required('Please select End Date.')
    }),

  // * Vehicle Details
  registrationDate: yup.date()
    .required('Registration Date is Required')
    .typeError('Invalid Registration Date.'),
  manufacturingYear: yup.number()
    .required('Please select Manufacturing Year.'),
  rto: yup.string()
    .required('Please select RTO.'),
  model: yup.string()
    .required('Please select Vehicle Model.'),
  engineNumber: yup.string()
    .required('Engine Number is required.')
    .min(8, 'Engine Number must be at least 8 characters long.')
    .max(17, 'Engine Number must not exceed 17 characters.')
    .test('matchesAnyRegex', 'Please enter a valid engine Number', (value) => {
        return allowedEngineNumberRegex.some(regex => value ? regex.test(value) : false);
    }),
  chassisNumber: yup.string()
    .required('Chassis Number is required.')
    .matches(/^(?!\d+$)(?![a-zA-Z]+$)[a-zA-Z\d]+$/, 'Chassis Number must be alphanumberic.')
    .min(17, 'Chassis number cannot be shorter than 17 characters. If Chassis Number is shorter please add zeroes (\'0\'s) before it.')
    .max(25, 'Chassis number cannot be longer than 25 characters.'),
  registrationNumber: yup.string()
    .when('newVehicle', {
      is: 'Y',
      then: yup.string()
        .optional()
        .matches(/^([A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}|[2-9][0-9]BH[0-9]{4}[A-HJ-NP-Z]{1,2})$/, {excludeEmptyString: true, message: 'Invalid Registration Number.'}),
      otherwise: yup.string()
        .required('Registration Number is required.')
        .matches(/^([A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}|[2-9][0-9]BH[0-9]{4}[A-HJ-NP-Z]{1,2})$/, 'Invalid Registration Number.')
    }),
  batteryNumber: yup.string()
    .when(['insurer','bodyType'], {
      is: (insurer, bodyType) => insurer === 'shriram' && bodyType === 'EV',
      then: yup.string()
        .required('Battery Number is required.')
        .matches(/^[A-Za-z0-9]+$/, 'Battery Number must be alphanumberic.'),
      otherwise:yup.string().optional()
    }),
  hypothecated: yup.string()
    .required('Please select if the vehicle is hypothecated.'),
  financer: yup.string()
    .when('hypothecated', {
      is: 'Y',
      then: yup.string()
        .required('Financer Name is required.')
        .matches(/^[0-9a-z &,.'-~|]+$/i, 'Invalid Financer Name.'),
      otherwise: yup.string()
    }),
  financerAddress: yup.string()
    .when('hypothecated', {
      is: 'Y',
      then: yup.string()
        .required('Financer Address is required.'),
      otherwise: yup.string()
    }),

  // * Inspection Details
  inspection: yup.object()
    .when(['insurer', 'transferCase', 'previousPolicyExpired'], {
      is: (insurer, transferCase, previousPolicyExpired) => {
        return insurer === 'united' && (
          transferCase === 'Y'
          || ['within 90 days', 'more than 90 days'].includes(previousPolicyExpired)
        );
      },
      then: yup.object({
        finalized: yup.boolean()
          .required('Finalize inspection to proceed.')
          .isTrue('Finalize inspection to proceed.'),
        front: yup.string()
          .required('Please upload required image.'),
        left: yup.string()
          .required('Please upload required image.'),
        right: yup.string()
          .required('Please upload required image.'),
        rear: yup.string()
          .required('Please upload required image.'),
        odometer: yup.string()
          .required('Please upload required image.'),
        chassisNumber: yup.string()
          .required('Please upload required image.'),
        report: yup.string()
          .required('Please upload required image.'),
        agent: yup.string()
          .required('Please upload required image.'),
        })
    }),

  // * Customer Details
  customerType: yup.string()
    .required('Customer Type is required.'),
  companyName: yup.string()
    .when(['customerType', 'insurer'], {
      is: (customerType, insurer) => {
        const enable = customerType === 'C'
                      && ['shriram', 'united','bajaj'].includes(insurer);
        return enable;
      },
      then: yup.string()
        .trim()
        .required('Company Name is required.')
        .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid Company Name.')
    })
    .when(['customerType', 'insurer'], {
      is: (customerType, insurer) => {
        const enable = customerType === 'C'
                      && !['shriram', 'united', 'bajaj'].includes(insurer);
        return enable;
      },
      then: yup.string()
        .trim()
        .required('Company Name is required.')
        .matches(/^[a-zA-Z0-9 ]+$/i, 'Invalid Company Name.'),
    }),
  companyType: yup.string()
  .when(['customerType', 'insurer'], {
    is: (customerType, insurer) => 
      customerType === 'C' && companyTypeInsurers.includes(insurer),
    then: yup.string().required('Please select a company type.'),
    otherwise: yup.string().nullable()
  }),
  owner: yup.object().when('companyType', {
    is: (companyType) => ['proprietor', 'partnership'].includes(companyType),
    then: ownerSchema,
    otherwise: yup.object().shape({}) // Empty schema when the condition is not met
  }),
  doi: yup.date()
    .when('customerType', {
      is: 'C',
      then: yup.date()
        .required('Date of Incorporation is required.')
        .typeError('Invalid Date of Incorporation.')
        .max(moment().format('YYYY-MM-DD'), 'Date of Incorporation cannot be after today.'),
    }),
  gstin: yup.string()
    .optional()
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'Invalid GSTIN Number.'),
  salutation: yup.string()
    .when('customerType', {
      is: 'I',
      then: yup.string()
        .required('Please select Salutation.'),
    }),
  firstName: yup.string()
    .when('customerType', {
      is: 'I',
      then: yup.string()
        .trim()
        .required('First Name is required.')
        .matches(/^(?!\.)(?:[a-z .']+)$/i, 'Invalid First Name.'),
    }),
  lastName: yup.string()
    .when('customerType', {
      is: 'I',
      then: yup.string()
        .trim()
        .required('Last Name is required.')
        .matches(/^[a-z .']+$/i, 'Invalid Last Name.'),
    }),
  dob: yup.date()
    .when('customerType', {
      is: 'I',
      then: yup.date()
        .required('DOB is required.')
        .typeError('Invalid DOB.')
        .max(maxDob, 'The Certificate Holder must be 18 years old.')
        .min(minDob, 'The Certificate Holder cannot be more than 70 years old.'),
    }),
  gender: yup.string()
    .when('customerType', {
      is: 'I',
      then: yup.string()
        .required('Please select Gender.'),
    }),
  mobile: yup.string()
    .required('Mobile is required.')
    .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile. Mobile must be 10 digits in length.'),
  email: yup.string()
    .required('Email is required.')
    .email('Invalid Email.'),
  pincode: yup.string()
    .required('Pincode is required.')
    .matches(/^(11[0-9]{3}[1-9]|11[0-9]{2}[1-9][0-9]|1[2-9][0-9]{4}|[2-9][0-9]{5})$/, 'Invalid Pincode.'),
  state: yup.string()
    .required('State is required. Enter a valid pincode to get State.'),
  city: yup.string()
    .required('City is required. Enter a valid pincode to get City.'),
  insurerSpecific: yup.object()
    .when(['pincode', 'insurer', 'state'], {
      is: (pincode, insurer, state) => {
        return pincode?.length === 6 && areaEnabledInsurers.includes(insurer) && state
      },
      then: yup.object({
        area: yup.string()
          .required('Please select Area.')
        })
    }),
  addressLine1: yup.string()
    .when('insurer', {
      is: 'united',
      then: yup.string()
        .required('Address Line 1 is required.')
        .matches(/^[^!#$&*_+="`'.:;(){}|<>,-/]+$/, 'Following special characters are not allowed: ! # $ & * _ + = " ` \' . : ; ( ) { } | < > , - /'),
      otherwise: yup.string()
        .required('Address Line 1 is required.')
        .max(30, 'Address Line 1 cannot be longer than 30 characters.'),
    }),
  addressLine2: yup.string()
    .when('insurer', {
      is: 'united',
      then: yup.string()
        .required('Address Line 2 is required.')
        .matches(/^[^!#$&*_+="`'.:;(){}|<>,-/]+$/, 'Following special characters are not allowed: ! # $ & * _ + = " ` \' . : ; ( ) { } | < > , - /'),
      otherwise: yup.string()
        .required('Address Line 2 is required.')
        .max(30, 'Address Line 2 cannot be longer than 30 characters.'),
    }),
  addressLine3: yup.string()
    .when('insurer', {
      is: 'united',
      then: yup.string()
        .matches(/(^[^!#$&*_+="`'.:;(){}|<>,-/]+$|^$)/, 'Following special characters are not allowed: ! # $ & * _ + = " ` \' . : ; ( ) { } | < > , - /'),
      otherwise: yup.string()
        .max(30, 'Address Line 3 cannot be longer than 30 characters.'),
    }),

  // * KYC Details

  // CKYC Type
  ckycType: yup.string()
    .required('CKYC Type is required.'),

  // Photo
  photoFileId: yup.string()
    .when('ckycType', {
      is: 'ckyc',
      then: yup.string().optional(),
      otherwise: yup.string().when('customerType', {
        is: (customerType) => (customerType && customerType === 'I'),
        then: yup.string()
        .required('Please upload selected document.'),
        otherwise: yup.string().optional()
      }),
    }),

  // Primary Document
  documentType: yup.string()
    .when('customerType', {
      is: 'I',
      then: yup.string()
        .required('Document Type is required.')
        .oneOf(['pan', 'aadhaar', 'voterId', 'drivingLicense', 'passport', 'form60'], 'Invalid Document Type.'),
      otherwise: yup.string()
        .required('Document Type is required.')
        .oneOf(['pan', 'gstin', 'cin'], 'Invalid Document Type.'),
    }),
  documentNumber: yup.string()
    .when('documentType', {
      is: 'pan',
      then: yup.string()
        .required('PAN is required.')
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN.'),
    })
    .when('documentType', {
      is: 'aadhaar',
      then: yup.string()
        .required('Aadhaar Number is required.')
        .matches(/^[2-9]{1}[0-9]{11}$/, 'Invalid Aadhaar Number.'),
    })
    .when('documentType', {
      is: 'voterId',
      then: yup.string()
        .required('Voter ID is required.')
        .matches(/^[A-Z]{3}[0-9]{7}$/, 'Invalid Voter ID Number.'),
    })
    .when('documentType', {
      is: 'drivingLicense',
      then: yup.string()
        .required('Driving License is required.')
        .matches(/^[A-Z]{2}[0-9]{2} (?:(?:19|20)[0-9][0-9])[0-9]{7}$/, 'Invalid Driving License Number. Accepted format - DL14 20110012345.'),
    })
    .when('documentType', {
      is: 'passport',
      then: yup.string()
        .required('Passport is required.')
        .matches(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/, 'Invalid Passport Number.'),
    })
    .when('documentType', {
      is: 'cin',
      then: yup.string()
        .required('CIN is required.')
        .matches(/^[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/, 'Invalid CIN Number.'),
    })
    .when('documentType', {
      is: 'gstin',
      then: yup.string()
        .required('GSTIN is required.')
        .matches(/^^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$$/, 'Invalid GSTIN Number.'),
    })
    .when('documentType', {
      is: 'form60',
      then: yup.string()
        .optional(),
    }),
  documentFileId: yup.string()
  .when('ckycType', {
    is: 'ckyc',
    then: yup.string()
      .optional(),
    otherwise: yup.string().required('Upload selected document.'),
  }),

  // Supporting Document
  supportingDocumentType: yup.string()
    .when('ckycType', {
      is: 'ckyc',
      then: yup.string()
        .optional(),
      otherwise: yup.string()
        .when('customerType', {
          is: 'I',
          then: yup.string()
            .required('Supporting Document Type is required.')
            .oneOf(['pan', 'aadhaar', 'voterId', 'drivingLicense', 'passport', 'form60'], 'Invalid Supporting Document Type.')
            .test(
              'document-mismatch',
              'Primary & supporting documents cannot be same.',
              (value, {parent: {documentType}}) => {
                let valid = true;
                if (documentType && documentType === value) {
                  valid = false;
                }
                return valid;
              }
            ),
          otherwise: yup.string()
            .required('Supporting Document Type is required.')
            .oneOf(['pan', 'gstin', 'cin'], 'Invalid Supporting Document Type.')
            .test(
              'document-mismatch',
              'Primary & supporting documents cannot be same.',
              (value, {parent: {documentType}}) => {
                let valid = true;
                if (documentType && documentType === value) {
                  valid = false;
                }
                return valid;
              }
            ),
        })
    }),
    //!!!!
  supportingDocumentNumber: yup.string()
    .when('ckycType', {
      is: 'ckyc',
      then: yup.string().optional(),
      otherwise: yup.string()
        .when('supportingDocumentType', {
          is: 'pan',
          then: yup.string()
            .required('PAN is required.')
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN.'),
        })
        .when('supportingDocumentType', {
          is: 'aadhaar',
          then: yup.string()
            .required('Aadhaar Number is required.')
            .matches(/^[2-9]{1}[0-9]{11}$/, 'Invalid Aadhaar Number.'),
        })
        .when('supportingDocumentType', {
          is: 'voterId',
          then: yup.string()
            .required('Voter ID is required.')
            .matches(/^[A-Z]{3}[0-9]{7}$/, 'Invalid Voter ID Number.'),
        })
        .when('supportingDocumentType', {
          is: 'drivingLicense',
          then: yup.string()
            .required('Driving License is required.')
            .matches(/^[A-Z]{2}[0-9]{2} (?:(?:19|20)[0-9][0-9])[0-9]{7}$/, 'Invalid Driving License Number. Accepted format - DL14 20110012345.'),
        })
        .when('supportingDocumentType', {
          is: 'passport',
          then: yup.string()
            .required('Passport is required.')
            .matches(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/, 'Invalid Passport Number.'),
        })
        .when('supportingDocumentType', {
          is: 'cin',
          then: yup.string()
            .required('CIN is required.')
            .matches(/^[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/, 'Invalid CIN Number.'),
        })
        .when('supportingDocumentType', {
          is: 'gstin',
          then: yup.string()
            .required('GSTIN is required.')
            .matches(/^^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$$/, 'Invalid GSTIN Number.'),
        })
        .when('supportingDocumentType', {
          is: 'form60',
          then: yup.string()
            .optional(),
      })
    }),
  supportingDocumentFileId: yup.string()
    .when('ckycType', {
      is: 'ckyc',
      then: yup.string().optional(),
      otherwise: yup.string().required('Upload selected document.') 
    }),

  // * Nominee Details
  nomineeName: yup.string()
    .when(['customerType','proprietor','insurer'], {
      is: (customerType, proprietor, insurer) => customerType === 'I' || (['united'].includes(insurer) && proprietor === 'Y'), //'I' || proprietor with nominee details needed
      then: yup.string()
        .required('Nominee Name is required.')
        .matches(/^[a-z ,.'-]+$/i, 'Invalid Nominee Name'),
    }),
  nomineeRelation: yup.string()
    .when(['customerType','proprietor','insurer'], {
      is: (customerType, proprietor, insurer) => customerType === 'I' || (['united'].includes(insurer) && proprietor === 'Y'),
      then: yup.string()
        .required('Please select / enter Nominee Relation.'),
    }),
  nomineeAge: yup.string()
    .when(['customerType','proprietor','insurer'], {
      is: (customerType, proprietor, insurer) => customerType === 'I' || (['united'].includes(insurer) && proprietor === 'Y'),
        then: yup.string()
          .required('Nominee Age is required.')
          .matches(/^[1-9][0-9]?$/, 'Nominee Age must be between 0 & 99.'),
      }),
  nomineeGender: yup.string()
    .when(['customerType','proprietor','insurer'], {
      is: (customerType, proprietor, insurer) => customerType === 'I' || (['united'].includes(insurer) && proprietor === 'Y'),
        then: yup.string()
          .required('Please select Nominee Gender.'),
      }),

  // * IDV & Premium Details
  idv: yup.number()
    .when(['policyType'], {
      is: (policyType) => policyType.includes('od'),
      then: yup.number()
        .min(1, 'IDV cannot be less than 1.')
        .required('Please enter IDV.')
    }),
  // premium: yup.number()
  //   .min(1, 'Premium cannot be less than 1.')
  //   .required('Please calculate premium.')
  premium: yup.number()
    .when(['quoteDisabled'], {
      is: true,
      then: yup.number()
        .optional(),
      otherwise: yup.number()
        .min(1, 'IDV cannot be less than 1.')
        .required('Please enter IDV.')
  }),
  registrationType:yup.string().required()
};

const customerType = [
  { label: 'Individual', value: 'I' },
  { label: 'Corporate', value: 'C' },
];

const salutation = [
  { value: 'MR', label: 'Mr.' },
  { value: 'MRS', label: 'Mrs.' },
  { value: 'MAST', label: 'Master' },
  { value: 'MS', label: 'Miss' },
];


const maritalStatus = [
  { label: 'Single', value: 'S' },
  { label: 'Married', value: 'M' },
  { label: 'Divorced', value: 'D' },
  { label: 'Widow / Widower', value: 'W' },
];

const thisYear = moment().format('YYYY');
const lastYear = moment().subtract(1, 'year').format('YYYY');
const manufacturingYear = [
  { label: thisYear, value: thisYear },
  { label: lastYear, value: lastYear },
];

const addonPlanType = [
  { label: 'Assistance + CPA : 5 Years', value: 'ACPA5Y' },
  { label: 'CPA + RSA + AHDC + DOC', value: 'CPA + RSA + AHDC + DOC' },
];

const plansByTypes = {
  'ACPA5Y': {
    creatable: false,
    plans: [
      { value: '1990', label: '1990' },
    ],
  },
  'CPA + RSA + AHDC + DOC': {
    creatable: true,
    plans: [
      { value: '1490', label: '1490' },
      { value: '1590', label: '1590' },
    ],
  },
};

const nomineeRelation = [
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Spouse', label: 'Spouse' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Son', label: 'Son' },
  { value: 'Daughter', label: 'Daughter' },
  { value: 'Legal Heir', label: 'Legal Heir' },
];

const policyTypes = {
  'Y': [
    { value: 'od1tp5', label: 'Bundled - 1 Year OD + 5 Year TP' },
  ],
  'N': [
    { value: 'od1tp1', label: 'Rollover Comprehensive - 1 Year OD + 1 Year TP' },
    { value: 'od1', label: 'Rollover - 1 Year OD Only' },
    // { value: 'tp1', label: 'Rollover - 1 Year TP Only' },
  ]
};

const previousPolicyExpired = [
  { value: 'not expired', label: 'Not expired' },
  { value: 'within 90 days', label: 'Expired within 90 days' },
  { value: 'more than 90 days', label: 'Expired more than 90 days ago' },
];

const previousNcb = [
  { value: '0', label: '0%' },
  { value: '20', label: '20%' },
  { value: '25', label: '25%' },
  { value: '35', label: '35%' },
  { value: '45', label: '45%' },
  { value: '50', label: '50%' },
];

const noOfClaimsInPreviousPolicy = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
  {value: '7', label: '7'},
  {value: '8', label: '8'},
  {value: '9', label: '9'},
  {value: '10', label: '10'},
];

const numberOfUnnamedPA = [
  {value: 1, label: '1'},
  {value: 2, label: '2'}
]
const companyTypes = [
  {
    label:'Company',
    value:'company'
  },
  {
    label:'Proprietor',
    value:'proprietor'
  },
  {
    label:'Partnership Firm',
    value:'partnership'
  },
  {
    label:'Corporate-Public Sector',
    value:'public'
  },
  {
    label:'Corporate-Private Sector',
    value:'private'
  },
]

const Fields = {
  customerType,
  salutation,
  maritalStatus,
  manufacturingYear,
  addonPlanType,
  plansByTypes,
  nomineeRelation,
  previousPolicyExpired,
  previousNcb,
  noOfClaimsInPreviousPolicy,
  numberOfUnnamedPA,
  companyTypes
};

const bodyTypes = [
  { value: 'BIKE', label: 'Bike' },
  { value: 'SCOOTER', label: 'Scooter' },
  ...USER?.use_new_config ? [{value:'EV', label:'EV'}]: []
]

const allInsurers: {
  [key in keyof typeof insurerName]: { [key: string]: any };
} & { united: insurerConfig, shriram:insurerConfig } = {
  bajaj: bajajConfig,
  liberty: libertyConfig,
  magma: magmaConfig,
  shriram: shriramConfig,
  united:unitedConfig,
  royal_sundaram: royalSundaramConfig,
  tata: tataConfig,
  oriental: orientalConfig,
  icici: iciciConfig,
  hdfc: hdfcConfig,
  chola: cholaConfig,
  kotak:kotakConfig,
  reliance:relianceConfig,
  national:nationalConfig
};

const getConditionalFields = (getValues: any) => {
  // Calculate vehicle age
  const { insurer, newVehicle, policyType } = getValues();
  const riskStartDate = moment(getValues('riskStartDate'));
  const registrationDate = moment(getValues('registrationDate') || (new Date()));
  const vehicleAge = riskStartDate.diff(registrationDate, 'years', true);

  // * Insurance Details
  const transferCase = () => newVehicle === 'N';
  const previousPolicyExpired = () => newVehicle === 'N';
  const claimInPreviousPolicy = () => newVehicle === 'N'
                                      && ['od1tp1', 'od1'].includes(policyType);
  const noOfClaimsInPreviousPolicy = () => getValues('claimInPreviousPolicy') === 'Y'
                                      && insurer === 'bajaj';
  const previousNcb = () => policyType
                            && ['od1tp1', 'od1'].includes(policyType)
                            && getValues('previousPolicyExpired')
                            && getValues('previousPolicyExpired') !== 'more than 90 days'
                            && getValues('claimInPreviousPolicy')
                            && getValues('claimInPreviousPolicy') === 'N';
  const zeroDep = () => (['od1', 'od1tp5'].includes(policyType) && vehicleAge < 5)
                        || (newVehicle === 'Y' && policyType === 'od1tp1');
  const consumables = () => zeroDep() && 
                          ((insurer === 'united' && vehicleAge < 4.5) ||
                            ['bajaj', 'magma', 'tata', 'icici'].includes(insurer));
  const engineProtect = () => getValues('bodyType') !== 'EV' && zeroDep() && ['bajaj', 'chola', 'united'].includes(insurer) && policyType === 'od1tp5';
  const rti = () => vehicleAge < 3 && ['united', 'tata', 'magma', 'icici', 'chola'].includes(insurer) && policyType !== 'od1tp1';
  const registrationCost = () => rti() && getValues('rti') === 'Y' && ['chola'].includes(insurer);
  const roadTax = () => rti() && getValues('rti') === 'Y' && ['chola'].includes(insurer);
  const paToUnnamedPerson = () => (paEnabledInsurers.includes(insurer) && policyType === 'od1tp5') || (insurer === 'united' && policyType === 'od1tp1');
  const numberOfUnnamedPa = () => paToUnnamedPerson() && getValues('paToUnnamedPerson') === 'Y';
  const electricalAccessories = () => electricalEnabledInsurers.includes(insurer) && policyType === 'od1tp5';
  const electricalAccessoriesValue = () => electricalAccessories() && getValues('electricalAccessories') === 'Y';
  const nonElectricalAccessories = () => nonElectricalEnabledInsurers.includes(insurer) && policyType === 'od1tp5';
  const nonElectricalAccessoriesValue = () => nonElectricalAccessories() && getValues('nonElectricalAccessories') === 'Y';
  const nonElectricalNames = () => nonElectricalAccessories() && getValues('nonElectricalAccessories') === 'Y' && accessoriesNameEnabledInsurers.includes(insurer)
  const electricalNames = () => electricalAccessories() && getValues('electricalAccessories') === 'Y' && accessoriesNameEnabledInsurers.includes(insurer);

  // * Previous Insurance Details
  const previousInsuranceDetails = () => newVehicle === 'N'
                                         && policyType;

  const previousOdInsurer = () => previousInsuranceDetails()
                                  && ['od1tp1', 'od1'].includes(policyType);
  const previousTpInsurer = () => previousInsuranceDetails()
                                  && ['od1', 'tp1'].includes(policyType);

  // * Vehicle Details
  const hypothecated = () => getValues('hypothecated') === 'Y';

  // * Customer Details
  const individualCustomer = () => getValues('customerType') === 'I';
  const businessCustomer = () => !individualCustomer();
  const companyTypeDropdown = () => businessCustomer() && companyTypeInsurers.includes(getValues('insurer'));
  const ownerFields = () => companyTypeDropdown() && ['partnership', 'proprietor'].includes(getValues('companyType'));
  const area = () => areaEnabledInsurers.includes(insurer);
  const financerDropdown = () => financerDropdownEnabledInsurers.includes(insurer);
  const handicap = () => handicapEnabledInsurers.includes(insurer);
  // const proprietor = () => businessCustomer() && ['tata'].includes(insurer)
  const nomineeNeeded = () => (['united'].includes(insurer) && getValues('proprietorName'))
  const addons = () => (zeroDep() || consumables() || engineProtect() || rti() || paToUnnamedPerson()) && policyType;
  // IDV & Premium Details
  const idv = () => policyType?.includes('od');
                    
  const fields = {
    // * Insurance Details
    transferCase,
    previousPolicyExpired,
    claimInPreviousPolicy,
    noOfClaimsInPreviousPolicy,
    previousNcb,
    idv,
    zeroDep,
    consumables,
    engineProtect,
    rti,
    registrationCost,
    roadTax,
    paToUnnamedPerson,
    numberOfUnnamedPa,
    electricalAccessories,
    electricalAccessoriesValue,
    nonElectricalAccessories,
    nonElectricalAccessoriesValue,
    electricalNames,
    nonElectricalNames,
    addons,
    // * Previous Insurance Details
    previousOdInsurer,
    previousOdPolicyNumber: previousOdInsurer,
    previousOdEndDate: previousOdInsurer,
    previousTpInsurer,
    previousTpPolicyNumber: previousTpInsurer,
    previousTpEndDate: previousTpInsurer,

    // * Vehicle Details
    financer: hypothecated,
    financerAddress: hypothecated,

    // * Customer Details
    businessCustomer,
    companyName: businessCustomer,
    salutation: individualCustomer,
    firstName: individualCustomer,
    lastName: individualCustomer,
    dob: individualCustomer,
    gender: individualCustomer,
    financerDropdown,
    area,
    companyTypeDropdown,
    ownerFields,
    handicap,

    // * Nominee Details
    nomineeNeeded,
    nomineeName: individualCustomer,
    nomineeRelation: individualCustomer,
    nomineeAge: individualCustomer,
    nomineeGender: individualCustomer,
  };

  return fields;
};

const checkFieldsValid = (getFieldState: UseFormGetFieldState<IFormInputs>, fields: Array<any>, getValues: any = () => true,kyc? : boolean) => {
  let valid = true;

  for (let field of fields) {
    const state = getFieldState(field);
    if ((state.isTouched && state.invalid) || !state.isTouched || !getValues(field)) {
      valid = false;
      break;
    }
  }
  
  return valid;
};
const useDummyConfig=false;
const getKycFields = (
  insurer: string,
  _customerType: string,
  ckycType: string,
  type: 'primary' | 'supporting' = 'primary',
) => {
  const customerType = _customerType === 'I' ? 'individual' : 'corporate' as const;
  ckycType = ckycType === 'ckyc' ? 'search' : 'upload';
  // if (1 === 1) {
    const insurerCkycConfig = useDummyConfig ? dummyUnitedConfig?.ckyc[ckycType]?.[customerType] : allInsurers[insurer]?.ckyc[ckycType]?.[customerType];
    if (!insurerCkycConfig) return null;
    const options: ArrayOfObjectsAny = [];
    const noInputFields = ['form60'];
    const ignoreKycFields = ['gstin', 'cin'];
    Object.keys(insurerCkycConfig).forEach((key) => {
      // console.log({ key , insurerCkycConfig})
      if (insurerCkycConfig[key][type])
        options.push({
          label: insurerCkycConfig[key].name,
          value: key,
          config: {
            validateKyc: noInputFields.includes(key) || ignoreKycFields.includes(key) ? false : true,
            documentNumber: noInputFields.includes(key) ? false : true, // kyc documentType fields have documentNumber true always in config
            ...insurerCkycConfig[key]
          },
        });
    });
    // console.log({ options });
    if(options.length===0)
    return null
    return options;
  // }

  // KYC document types
  // const documentConfig = {
  //   aadhaar: {
  //     name: 'Aadhaar Card'
  //   },
  //   drivingLicense: {
  //     name: 'Driving License'
  //   },
  //   voterId: {
  //     name: 'Voter ID'
  //   },
  //   passport: {
  //     name: 'Passport'
  //   },
  //   pan: {
  //     name: 'PAN Card'
  //   },
  //   form60: {
  //     name: 'Form 60',
  //     config: {
  //       validateKyc: false,
  //       documentNumber: false
  //     }
  //   },
  //   gstin: {
  //     name: 'GSTIN',
  //     config: {
  //       validateKyc: false
  //     }
  //   },
  //   cin: {
  //     name: 'CIN'
  //   },
  // };

  // // Check list of upload documents
  // const insurerCkycConfig = allInsurers[insurer].ckyc;
  // let currentKycConfig: any = [];
  // if (ckycType === 'upload') {
  //   currentKycConfig = insurerCkycConfig[customerType][type];
  // } else {
  //   if (customerType === 'individual') {
  //     currentKycConfig.push('aadhaar');
  //   }

  //   if (!(customerType === 'individual' && insurer === 'magma')) {
  //     currentKycConfig.push('pan');
  //   }
  // }

  // const options: ArrayOfObjectsAny = [];
  // for (const document of currentKycConfig) {
  //   const currentDocumentConfig = documentConfig[document];
  //   const option = {
  //     label: currentDocumentConfig.name,
  //     value: document,
  //     config: {
  //       validateKyc: currentDocumentConfig?.config?.validateKyc === false ? false : true,
  //       documentNumber: currentDocumentConfig?.config?.documentNumber === false ? false : true,
  //       frontAndBack: insurerCkycConfig?.frontAndBack?.includes(document) ? true : false
  //     }
  //   };
  //   options.push(option);
  // }

  // return options;
};
const getInsurerDependents = ({ insurer }) => {
  const insurerDependent = allInsurers[insurer]?.ckyc.dependent;
  if(!useDummyConfig)
  return insurerDependent;
  return dummyUnitedConfig.ckyc.dependent;
}
const getCkycTypeDependents = ( {insurer, ckycType} ) => {
  ckycType = ckycType === 'ckyc' ? 'search' : 'upload';
  const ckycTypeDependent = allInsurers[insurer]?.ckyc[ckycType]?.dependent;
  if(!useDummyConfig)
  return ckycTypeDependent;
  return dummyUnitedConfig.ckyc[ckycType]?.dependent;
}
const getCustomerTypeDependents = ({ insurer, customerType ,ckycType }) => {
  ckycType = ckycType === 'ckyc' ? 'search' : 'upload';
  customerType = customerType === 'I' ? 'individual' : ('corporate' as const);
  const customerTypeDependent = allInsurers[insurer]?.ckyc[ckycType]?.[customerType]?.dependent;
  if(!useDummyConfig)
  return customerTypeDependent;
  return dummyUnitedConfig.ckyc[ckycType]?.[customerType]?.dependent
}
const toggleValidation = (type:'required' | 'optional', fields:FieldNameKey[]) => {
  fields.map((fieldName:FieldNameKey) => {
    const optionalFieldValidation = yup.object().shape({
      [fieldName]: yup.string().optional(),
    });
    const validationRulesOptional = optionalFieldValidation.fields[fieldName];
    const RequiredFieldValidation = yup.object().shape({
      [fieldName]: initialValidation[fieldName],
    });
    const validationRulesRequired = RequiredFieldValidation.fields[fieldName];

    const finalValidation = type === 'required' ? validationRulesRequired : validationRulesOptional;
    store.dispatch(modifyValidationAction({ [fieldName]: finalValidation }));

  })
}
const registrationTypeFields = [ 
  { label:'Private', value:'private'}, 
  { label:'Hire/Purchase', value:'hirepurchase' }
]

const allowedInsurerTypes = {
  'od1tp5': ['bike', 'bikeBackup', 'scooter', 'scooterBackup', 'ev', 'evBackup'],
  'od1': ['bikeA', 'bikeB', 'bikeC', 'scooterA', 'scooterB', 'scooterC', 'evA', 'evB', 'evC'],
  'od1tp1': ['bikeA', 'bikeB', 'bikeC', 'scooterA', 'scooterB', 'scooterC', 'evA', 'evB', 'evC']
}

/**
 *? List of Dealers for Liberty Insurance
 */
const libertyDealers = [
  'ACPL00001',
  'ACPL00705',
  'ACPL00916',
  'ACPL00690',
  'ACPL00641',
  'ACPL00521',
  'ACPL01010',
  'ACPL01105',
  'ACPL00556',
  'ACPL00001',
  'ACPL00015',
  'ACPL00217',
  'ACPL00218',
  'ACPL00404',
  'ACPL00405',
  'ACPL00406',
  'ACPL00407',
  'ACPL00415',
  'ACPL00425',
  'ACPL00432',
  'ACPL00438',
  'ACPL00443',
  'ACPL00444',
  'ACPL00449',
  'ACPL00450',
  'ACPL00451',
  'ACPL00452',
  'ACPL00453',
  'ACPL00454',
  'ACPL00456',
  'ACPL00469',
  'ACPL00470',
  'ACPL00471',
  'ACPL00472',
  'ACPL00473',
  'ACPL00474',
  'ACPL00476',
  'ACPL00478',
  'ACPL00479',
  'ACPL00480',
  'ACPL00481',
  'ACPL00482',
  'ACPL00483',
  'ACPL00484',
  'ACPL00485',
  'ACPL00486',
  'ACPL00494',
  'ACPL00500',
  'ACPL00501',
  'ACPL00504',
  'ACPL00505',
  'ACPL00506',
  'ACPL00507',
  'ACPL00508',
  'ACPL00511',
  'ACPL00512',
  'ACPL00513',
  'ACPL00514',
  'ACPL00516',
  'ACPL00517',
  'ACPL00520',
  'ACPL00521',
  'ACPL00525',
  'ACPL00526',
  'ACPL00527',
  'ACPL00531',
  'ACPL00534',
  'ACPL00535',
  'ACPL00536',
  'ACPL00537',
  'ACPL00538',
  'ACPL00540',
  'ACPL00541',
  'ACPL00542',
  'ACPL00544',
  'ACPL00546',
  'ACPL00548',
  'ACPL00556',
  'ACPL00561',
  'ACPL00562',
  'ACPL00564',
  'ACPL00565',
  'ACPL00566',
  'ACPL00567',
  'ACPL00571',
  'ACPL00572',
  'ACPL00574',
  'ACPL00578',
  'ACPL00579',
  'ACPL00582',
  'ACPL00583',
  'ACPL00584',
  'ACPL00587',
  'ACPL00588',
  'ACPL00589',
  'ACPL00591',
  'ACPL00592',
  'ACPL00596',
  'ACPL00603',
  'ACPL00604',
  'ACPL00606',
  'ACPL00614',
  'ACPL00617',
  'ACPL00619',
  'ACPL00622',
  'ACPL00623',
  'ACPL00625',
  'ACPL00627',
  'ACPL00628',
  'ACPL00629',
  'ACPL00631',
  'ACPL00632',
  'ACPL00633',
  'ACPL00634',
  'ACPL00635',
  'ACPL00636',
  'ACPL00637',
  'ACPL00641',
  'ACPL00642',
  'ACPL00646',
  'ACPL00648',
  'ACPL00665',
  'ACPL00670',
  'ACPL00688',
  'ACPL00690',
  'ACPL00696',
  'ACPL00704',
  'ACPL00705',
  'ACPL00916',
  'ACPL00709',
  'ACPL00710',
  'ACPL00721',
  'ACPL00742',
  'ACPL00746',
  'ACPL00747',
  'ACPL00754',
  'ACPL00764',
  'ACPL00771',
  'ACPL00772',
  'ACPL00775',
  'ACPL00777',
  'ACPL00785',
  'ACPL00792',
  'ACPL00807',
  'ACPL00820',
  'ACPL00822',
  'ACPL00899',
  'ACPL00919',
  'ACPL00921',
  'ACPL00926',
  'ACPL01010',
  'ACPL01011',
  'ACPL01033',
  'ACPL01044',
  'ACPL01051',
  'ACPL01056',
  'ACPL01061',
  'ACPL01076',
  'ACPL01105',
  'ACPL01164',
  'ACPL01097',
  'ACPL00454',
  'ACPL00454'
];

const makeUnitedToLiberty = {
  "HONDA": "HONDA",
  "HERO MOTOCORP": "HERO MOTOCORP",
  "BAJAJ AUTO LTD": "BAJAJ"
};

export {
  allowedInsurerTypes,
  Fields,
  allInsurers,
  bodyTypes,
  checkFieldsValid,
  getConditionalFields,
  getKycFields,
  getInsurerDependents,
  getCkycTypeDependents,
  getCustomerTypeDependents,
  libertyDealers,
  makeUnitedToLiberty,
  maxDob,
  minDob,
  noOptionsMessage,
  policyTypes,
  initialValidation,
  registrationTypeFields,
  toggleValidation,
};
